@import "~/styles/config.scss";

.missionContainer {
  .text {
    margin: 0;
  }
  .missionCont {
    margin-top: 20px;
    max-width: 100%;
    @include sm {
      max-width: 500px;
    }
  }
}
