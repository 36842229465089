@import "~/styles/config.scss";

.descriptionWrapper {
  text-align: center;
  width: 100%;
  display: inline;
  display: flex;
  flex-direction: row;
  line-height: 24px;
  height: 24px;
  &:before,
  &:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid $karl-grey;
    margin: auto;
  }

  .description {
    margin: 0 6px 0px 6px;
  }
}
