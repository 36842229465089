@import "~/styles/config.scss";

.formContainer {
  display: flex;
  flex-direction: column;
}

.titleBar {
  display: flex;
  flex-direction: column;
  .title {
    margin-top: 0;
  }
}

.addNew {
  margin-top: 10px !important;
  margin-bottom: 20px !important;
}

.header {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 5px;
  padding-left: 5px;
  display: flex;
  align-content: center;
  justify-content: space-between;
  h6 {
    display: flex;
    align-items: center;
    margin: 0;
  }
  button {
    height: 38px;
    width: 38px;
    display: flex;
    align-items: center;
    color: #c4c4c4;
    font-size: 15px;
  }
  button:hover {
    color: $resource-midnight-blue;
  }
}

.primaryText {
  div {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}

.secondaryText {
  div {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
}
