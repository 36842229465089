@import "~/styles/config.scss";

@mixin date {
  min-width: 125px;
  margin-right: 15px;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 18px;
}

.timeContainer {
  margin: 20px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .times {
    display: flex;
    flex-flow: row nowrap;
    overflow-x: scroll;
    width: 100%;

    @include sm {
      overflow-x: hidden;
      flex-wrap: wrap;
    }

    .addToCalendar {
      font-size: 14px;
      line-height: 18px;
      color: black;
      text-decoration: underline;
    }
  }

  .title {
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 5px;
    line-height: 25px;
  }

  .date {
    @include date;
  }

  .pastDate {
    @include date;
    opacity: 0.2;

    div {
      cursor: default !important;
    }
  }

  .dateTime {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 8px;
  }
}
