@import "~/styles/config.scss";
.chip {
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 8px;
  cursor: pointer;
  &:last-child {
    margin-right: 0;
  }

  &.selected {
    background-color: $karl-grey;
    border-color: $royal-purple;
  }
}
