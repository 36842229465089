.container {
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .text {
    margin-bottom: 15px;
  }
}

.defaultHeight {
  height: 400px;
}
