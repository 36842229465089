@import "~/styles/config.scss";

.headerGreetingContainer {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  .greeting {
    margin-top: 5px;
  }
  .role {
    margin-top: 5px;
    color: #a0a0a0;
  }
}
