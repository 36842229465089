@import "~/styles/config.scss";

.moduleCont {
  max-width: 470px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 50px;
  min-width: 420px;
  margin-bottom: 20%;
  .card {
    padding: 0px 25px;
    padding-bottom: 25px;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}
