@import "~/styles/config.scss";

.cardsInfoContainer {
  display: flex;
  flex-direction: column;
  .header,
  .description {
    margin-bottom: 10px;
  }
  .cardsContainer {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    &:after {
      content: "";
      flex: 0 0 48%;
      @include sm {
        flex: 0 0 32%;
      }
    }
  }
}
