@import "~/styles/config.scss";

.brandContainer {
  .text {
    margin: 0;
  }
  .brandColorInputFlex {
    margin-top: 20px;
    display: flex;
    .primaryColorPreview {
      content: "";
      width: 56px;
      height: 56px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    .primaryColorTextField > div {
      border-radius: 0px 4px 4px 0px;
    }
  }
}
