@import "~/styles/config.scss";

.formContainer {
  display: flex;
}

.previewContainer {
  vertical-align: top;
  position: relative;
  width: 25%;
  border: 1px solid #d7d6d9;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  .icon {
    color: $karl-gray-8;
  }
  .preview {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    position: absolute;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background: white;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0;
  }
}

.dropzone {
  height: 90px;
  width: 75%;
  background: #f9f8f9;
  border-top: 1px dashed #d7d6d9;
  border-right: 1px dashed #d7d6d9;
  border-bottom: 1px dashed #d7d6d9;
  box-sizing: border-box;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.verticalPreviewContainer {
  vertical-align: top;
  position: relative;
  height: 250px;
  width: 250px;
  border: 1px solid #d7d6d9;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  .icon {
    color: $karl-gray-8;
  }
  .preview {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    position: absolute;
    background: white;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin: 0;
  }
}

.verticalDropzone {
  height: 90px;
  width: 250px;
  background: #f9f8f9;
  border-left: 1px dashed #d7d6d9;
  border-right: 1px dashed #d7d6d9;
  border-bottom: 1px dashed #d7d6d9;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dragError {
  border: 1px dashed red;
}

.dragActive {
  border: 1px dashed blue;
}

.dropzoneComponent {
  display: flex;
  align-items: center;
  margin: auto;
  color: #a5a1b3;
}

.dropzoneIcon {
  padding-right: 10px;
}

.dropzoneText {
  color: #a5a1b3;
}
