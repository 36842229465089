.container {
  padding: 0 24px;
  display: flex;
}

.editor {
  padding-top: 60px;
  flex: 1;
  flex-direction: column;
  display: flex;
  margin-right: 20px;

  .backButton {
    align-self: flex-start;
  }
}

.preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  flex: 1;
}
