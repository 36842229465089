.dialogCont {
  padding: 30px;
  display: flex;
  flex-direction: column;

  .buttonContainer {
    display: flex;
    justify-content: center;
    button:not(:last-child) {
      margin-right: 10px;
    }
    button {
      min-width: 180px;
    }
  }
}
