@import "~/styles/config.scss";

.logoContainer {
  display: flex;
  .text {
    margin: 0;
  }
  .updateLogoCont {
    width: 100%;
    margin-top: 20px;
    @include sm {
      width: 500px;
    }
  }
  .updateInterviewerCont {
    width: 100%;
    margin-top: 20px;
    margin-left: 50px;
    @include sm {
      width: 500px;
    }
  }
}
