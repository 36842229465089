@import "~/styles/config.scss";
@import "~/styles/variables.scss";

.locationsModalCont {
  margin: 10px;

  .headerTextCont {
    padding: 0px 8px 0px 8px;
  }

  .locationsFlexCont {
    display: flex;
    flex-wrap: wrap;

    .locationCard {
      width: 200px;
      height: 200px;
      margin: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      .actionArea {
        height: 100%;
        width: 100%;
        background-size: cover;

        .text {
          margin: 0;
        }
        .address {
          overflow: hidden;
          max-height: 0px;
          opacity: 0;
          transition: max-height 0.2s ease-in-out, opacity 0.3s ease-in-out;
        }
        &:hover .address {
          opacity: 1;
          max-height: 50px;
        }
        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }

    .locationCard.selectedLocation {
      border-radius: 8px;
      border: 4px solid $primary;
    }

    .locationCard.archivedLocation {
      opacity: 0.2;
    }
  }
}
