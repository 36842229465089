@import "./modules/colors";

// Screen size variables
$screen-xs-min: 425px; // Tiny phones
$screen-sm-min: 600px; // Small tablets and large smartphones (landscape view)
$screen-md-sm-min: 800px; // Small tablets (portrait view
$screen-md-min: 1000px; // Small tablets (portrait view)
$screen-lg-min: 1200px; // Tablets and small desktops
$screen-xl-min: 1400px; // Large tablets and desktops

// Mixins
@mixin xs {
  @media (min-width: #{$screen-xs-min}) {
    @content;
  }
} // Tiny devices
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
} // Small devices
@mixin mdSm {
  @media (min-width: #{$screen-md-sm-min}) {
    @content;
  }
} // Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
} // Medium devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
} // Large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
} // Extra large devices

//
// Helper Classes
//

@mixin containerStyles {
  // xs: mobile first
  padding-left: 2%;
  padding-right: 2%;
  max-width: 1600px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 30px;
  @include sm {
    padding-left: 5%;
    padding-right: 5%;
  }
  @include md {
    padding-left: 5%;
    padding-right: 5%;
  }
  @include lg {
    padding-left: 5%;
    padding-right: 5%;
  }
  @include xl {
    padding-left: 5%;
    padding-right: 5%;
  }
}

.container {
  @include containerStyles;
}

$sidePanelSm: 7%;
$sidePanelMd: 7%;
$sidePanelLg: 10%;
$sidePanelXl: 15%;

@mixin sidePanelContainerStyles {
  padding-left: $sidePanelSm;
  padding-right: $sidePanelSm;
  @include md {
    padding-left: $sidePanelMd;
    padding-right: $sidePanelMd;
  }
  @include lg {
    padding-left: $sidePanelLg;
    padding-right: $sidePanelLg;
  }
  @include xl {
    padding-left: $sidePanelXl;
    padding-right: $sidePanelXl;
  }
}

legend {
  width: inherit;
}

@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}

@mixin bottomFadeEffect {
  & {
    position: relative;
  }
  &:after {
    content: "";
    position: absolute;
    height: 60px;
    width: 100%;
    bottom: 0;
    left: 0;
    border-radius: 5px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 50%,
      #ffffff 100%
    );
  }
}
