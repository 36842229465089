@import "~/styles/config.scss";

.missionContainer {
  display: flex;
  flex-direction: column;
  img.companyTeamImage {
    margin-top: 20px;
    max-width: 100%;
  }
}
