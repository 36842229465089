@import "~/styles/config.scss";

.integrationsCard {
  margin-right: 20px;
  padding: 40px 20px 20px 20px;
  flex-direction: column;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 340px;

  .brand {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;

    .icon {
      width: 80px;
      height: 80px;
    }
  }

  .button {
    width: 100%;
    color: $tehama-purple;
    background-color: tint($royal-purple, 90%);
  }

  .addToSlack {
    margin-top: 20px;
  }
}

.integrationsCard.connected {
  .button {
    border-color: $success-green;
    color: $success-green;
    background-color: tint($success-green, 90%);
  }

  .buttonIcon {
    margin-right: 5px;
  }
}
