@import "~/styles/config.scss";

.formContainer {
  display: flex;
  flex-direction: column;
}

.titleBar {
  display: flex;
  flex-direction: column;
  .title {
    margin-top: 0;
  }
}
