.moduleCardContainer {
  height: 60px;
  padding: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f9f8f9;
  color: #79728c;
  transition: background 2s, border-color 2s;

  &.highlighted {
    background: #f1edff;
    border-color: #6741e6;
  }

  &:not(last-child) {
    margin-bottom: 20px;
  }
  .controlsCont {
    display: flex;
    .controlsButton {
      &:not(last-child) {
        margin-right: 10px;
      }
      .controlsIcon {
        font-size: 20px;
        transition: color 0.3s ease-out;

        &.delete:hover {
          color: red;
        }
        &:not(.delete):hover {
          color: black;
        }
      }
    }
  }

  .dragHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
