@import "~/styles/config.scss";

.guideContainer {
  width: 100%;
  max-width: 550px;
  box-sizing: border-box;
  margin-bottom: 20px;
  @include sm {
    border: 1px solid #e3e2e5;
    border-radius: 10px;
  }
  padding: 0px 25px;
  padding-bottom: 25px;
  .stepper {
    padding: 0;
  }
}
