@import "~/styles/config.scss";

.stageTemplateEditorContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .backButton {
    width: max-content;
    padding-left: 10px;
    margin-left: -10px;
    justify-content: flex-start;
    .icon {
      height: 23px;
    }
  }

  .headerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 33px;

    .moduleName {
      border-bottom: 1px solid #d7d6d9;
      margin-bottom: 33px;
    }

    .templateName {
      flex-grow: 1;
    }
    .iconButton {
      flex-shrink: 0;
      margin-top: 20px;
      margin-left: 10px;
    }
  }

  .stageTemplateConfigSettings {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .nonListableModulesConfig {
      width: 48%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .nonListableModule {
        width: 100%;
        display: flex;
        align-items: center;
        &:not(:last-child) {
          margin-bottom: 5px;
        }
        .moduleSwitch {
          margin-right: 20px;
        }
      }
    }
  }
}

.modalContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
  .text {
    margin: 0;
  }
  .addModulesModalContainer {
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    &:after {
      content: "";
      flex: 0 0 48%;
      @include sm {
        flex: 0 0 32%;
      }
    }
    .addModulesCard {
      margin-bottom: 10px;
      width: 48%;
      @include sm {
        width: 32%;
      }
      .actionArea {
        height: 100%;
        min-height: 150px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .headerText {
          margin: 0px 0px 10px 0px;
        }
      }
    }
  }
}

.nonListableInfoIcon {
  margin-left: 6px;
}
