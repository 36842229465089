@import "~/styles/config.scss";

.header {
  display: flex;
  .stepTitle {
    flex: 1;
  }
}

.closeIcon {
  color: $karl-gray-8;
  cursor: pointer;
}

.feedbackForOtherCompaniesValueProp {
  color: $resource-midnight-blue-300;
}

.feedbackForOtherCompaniesCTA {
  color: $royal-purple;
  font-weight: 600;
}
