@import "~/styles/config.scss";

.gridCont {
  height: 100vh;
  display: grid;
  $headerHeight: 80px;
  grid-template-rows: $headerHeight 1fr;
  grid-template-columns: 1fr;
  grid-template-areas:
    "header"
    "sidebar";
  @include md {
    grid-template-rows: $headerHeight 1fr;
    grid-template-columns: 420px 1fr;
    grid-template-areas:
      "header header"
      "sidebar content";
  }
  .header {
    grid-area: header;
    display: flex;
    align-items: center;
    padding: 0px 45px;
    box-shadow: 0px 4px 15px rgba(218, 218, 218, 0.5);
  }
  .sidebar {
    grid-area: sidebar;
    background: #f9f8f9;
    padding: 32px 45px;
    overflow-y: hidden;
    overflow-x: hidden;
  }
  .content {
    display: none;
    grid-area: content;
    padding-top: 20px;
    @include containerStyles;
    @include md {
      display: inherit;
    }
  }
}

.loginCont {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h3 {
    margin-top: 0;
  }
  .bodyText {
    margin-bottom: 26px;
  }
}

.gettingStarted {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  opacity: 0.5;
  img {
    max-height: 75%;
    max-width: 100%;
  }
}
