@import "~/styles/config.scss";

.header {
  margin-top: 0;
  margin-bottom: 10px;
}

.container {
  padding: 30px;
  max-width: 500px;
}
