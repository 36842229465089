@import "~/styles/config.scss";

.arrivalInstructionsContainer {
  padding: 12px;
  background-color: $kent-gray-20;
  width: 100%;
  border-radius: 5px;
  margin-top: 12px;

  .fade {
    overflow: hidden;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      height: 40px;
      width: 100%;
      bottom: 0;
      left: 0;
      border-radius: 5px;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        #f5f6f7 100%
      );
    }
  }

  .withoutFade {
    overflow: hidden;
    position: relative;
  }

  .seeMoreWrapper {
    margin-top: 10px;
    .seeMore {
      text-align: center;
      cursor: pointer;
      color: $kent-gray-200;
      .chevron {
        margin-left: 4px;
        transition: 0.5s ease-in-out;
      }
    }
  }
}
