@import "~/styles/config.scss";

.addressContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .links {
    display: flex;
    .link {
      text-decoration: underline;
    }
    .link:not(:last-child) {
      margin-right: 10px;
    }
  }

  .map {
    width: 100%;
    margin-top: 12px;
  }
}
