@import "~/styles/config.scss";

.chipsInfoContainer {
  display: flex;
  flex-direction: column;
  .header,
  .description {
    margin-bottom: 10px;
  }
  .chipsContainer {
    display: flex;
    flex-wrap: wrap;
    .chip {
      margin-right: 10px;
      margin-bottom: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
    a {
      text-decoration: none;
      &:hover {
        color: inherit;
      }
    }
  }
}
