@import "~/styles/config.scss";

.card {
  margin-top: 20px;
  width: 100%;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  .secondaryFlex {
    max-width: 95%;
    display: flex;
    align-items: center;
    .emoji {
      display: flex;
      margin: 0;
      margin-right: 24px;
    }
  }
  .icon {
    margin-left: 20px;
    color: #000;
  }
}

.linkIcon {
  max-width: 36px;
  max-height: 40px;
  vertical-align: middle;
}
