@import "~/styles/config.scss";

.templateCard {
  width: 100%;
  margin-bottom: 20px;
  cursor: pointer;
  @include mdSm {
    width: 49%;
  }
  @include lg {
    width: 32%;
  }
  .cardButton {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    h4 {
      margin-top: 0;
    }

    &:hover .titleContainer .controlsButton {
      display: flex;
    }

    .titleContainer {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .controlsButton {
        display: none;
        font-size: 20px;
        padding: 0;
        .controlsIcon {
          &.delete:hover {
            color: red;
          }
        }
      }
    }
    .info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .createdBy {
        display: flex;
        align-items: center;
        .logoContainer {
          display: flex;
          align-items: center;
        }
        .company {
          font-weight: 700;
        }
      }
      .logo {
        height: 15px;
        width: 15px;
        margin-left: 5px;
      }
    }
  }
}

.createTemplateContainer {
  display: flex;
  cursor: pointer;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
