@import "~/styles/config.scss";

.gridContainer {
  display: grid;
  grid-gap: 10px;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  grid-template-areas: "preview";
  &:not(.hideContent) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "editor preview";
  }
  height: 100%;

  .editor {
    min-width: 420px;
    grid-area: editor;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    flex: 1;
    flex-direction: column;
    display: flex;

    .wrapper {
      padding: 20px 20px 40px 20px;
    }
  }

  .preview {
    grid-area: preview;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    flex: 1;
  }
}
