@import "~/styles/config.scss";

.companyContainer {
  display: flex;
  flex-direction: column;
  .headerCont {
    .headerText {
      margin: 0;
    }
    .subtitleText {
      margin-top: 7px;
      display: flex;
      align-items: center;
      color: $textGrey;
    }
  }
  .editCont {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    & > div {
      margin-top: 35px;
    }
  }
}
