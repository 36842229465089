@import "~/styles/config.scss";

.cont {
  display: flex;
  flex-direction: column;
  &.disableMargin {
    padding-top: 0px;
    padding-left: 0;
    padding-right: 0;
  }
  .headerCont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .headerText {
      margin: 0;
    }
  }
  .templatesContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 40px;
    &:after {
      content: none;
      @include mdSm {
        content: "";
        flex: 0 0 49%;
      }
      @include lg {
        flex: 0 0 32%;
      }
    }
  }
}

.addButton {
  margin-top: 20px;
}
