@import "~/styles/config.scss";

.valuesContainer {
  .headerCont {
    display: flex;
    .text {
      margin: 0;
      margin-right: 20px;
    }
  }
  .valueCardsCont {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    .valueCard {
      margin-bottom: 20px;
      width: 200px;
      height: 300px;
      padding: 20px;
      cursor: pointer;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      &.headerText {
        margin-top: 0;
      }
      &:not(:last-child) {
        margin-right: 20px;
      }
      @include bottomFadeEffect;
    }
    .description {
      overflow-wrap: anywhere;
    }
  }
}

.dialogCont {
  padding: 30px;
  display: flex;
  flex-direction: column;
  & > *:not(:last-child) {
    margin-bottom: 30px;
  }
  .headerText {
    margin-top: 0;
  }
  .buttonContainer {
    display: flex;
    justify-content: center;
    button:not(:last-child) {
      margin-right: 10px;
    }
    button {
      min-width: 180px;
    }
  }
}
