@import "~/styles/config.scss";

$default-link-color: grey;
$link-card-border: 1px solid #e2e2e2;

.listItems {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.listItem {
  color: $resource-midnight-blue;
  box-shadow: 0px 4px 15px rgba(157, 157, 157, 0.25);
  width: 100%;
  border: $link-card-border;
  margin-bottom: 6px;

  @include sm {
    width: 49%;
  }

  .listItemImage {
    height: 140px;
    border-bottom: $link-card-border;
  }

  .defaultImage {
    height: 140px;
    border-bottom: $link-card-border;
    background-size: contain;
  }

  .loading {
    display: flex;
    flex-direction: column;
  }

  .url {
    color: $default-link-color;
    font-family: $resource-primary-font;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
  }

  a {
    color: $default-link-color;
  }

  h6 {
    margin-top: 0;
  }

  &:hover {
    color: $default-link-color;
  }
}
