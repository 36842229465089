@import "~/styles/config.scss";

.gridCont {
  display: grid;
  grid-template-columns: 104px auto;
  grid-template-rows: auto;
  grid-template-areas: "sidebar main";
  .sidebar {
    position: fixed;
    grid-area: sidebar;
    height: 100vh;
    padding: 12px;
    width: 100px;
    z-index: 1000;
  }
  .mainCont {
    grid-area: main;
    min-height: 100vh;
    $headerHeight: 75px;
    scroll-margin-bottom: 50px;
    header {
      @include containerStyles;
      height: $headerHeight;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .customerSwitcher {
        width: 200px;
        margin: 0;
        .customerSwitcherTextField {
          & > div {
            padding-bottom: 0;
            padding-top: 0;
          }
        }
      }
    }
    .contentCont {
      @include containerStyles;
      min-height: calc(100% - #{$headerHeight});
    }
    .footer {
      height: 50px;
      background: transparent;
    }
    .contentCont.fullHeight {
      margin-top: 60px;
      height: 100%;
    }
  }
}
