@import "~/styles/config.scss";

.PhoneScreenHeaderContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  background: #f1f1f1;
  border-radius: 8px;
  padding: 15px;
  .linksContainer {
    display: flex;
    justify-content: space-around;
    .button {
      text-transform: uppercase;
    }
  }
}
