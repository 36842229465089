@import "~/styles/config.scss";

.scheduleContainer {
  display: flex;
  flex-direction: column;

  .eventBreak {
    margin: 15px 0px 15px 0px;
  }
}
