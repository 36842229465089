@import "~/styles/config.scss";

.guidesCont {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  $headerHeight: 58px;
  .headerCont {
    height: $headerHeight;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .headerText {
      margin: 0;
    }
  }
  .gettingStarted {
    overflow: hidden;
    height: calc(100% - #{$headerHeight});
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    h3 {
      font-weight: 500;
    }
    h4 {
      margin: 0px 0px 45px 0px;
    }
    img {
      height: 75%;
    }
  }
  .tableCont {
    margin-top: 40px;
    .table {
      .guideItemRow {
        cursor: pointer;

        .hoverIcons {
          display: flex;
          justify-content: flex-end;
          display: flex;
          opacity: 0;
          transition: opacity 0.3s ease-out;
          .link {
            display: flex;
            align-items: center;
            cursor: pointer;
            padding-right: 20px;
            padding-left: 20px;
          }
        }
        .text {
          font-weight: bold;
          margin-left: 5px;
        }
        &:hover {
          background-color: $karl-gray-2;
          .hoverIcons {
            opacity: 1;
            justify-content: flex-end;
          }
        }
        .linksCell {
          padding-right: 0px;
        }
        .link {
          display: flex;
          align-items: center;
          cursor: pointer;
          font-weight: bold;
        }

        .shareLink {
          display: flex;
          align-items: center;
          cursor: pointer;
          font-weight: bold;
        }

        .rowIcon {
          font-weight: bold;
        }

        .iconShare {
          display: flex;
          font-weight: bold;
          color: $royal-purple;

          .text {
            font-weight: bold;
            margin-left: 5px;
          }
        }

        .alreadyShared {
          color: $dark-text;
          font-weight: normal;
        }
      }

      .icon {
        margin-left: 5px;
      }
    }
  }
}
