@import "~/styles/config.scss";

.header {
  margin-top: 0;
  margin-bottom: 10px;
}

.inGuide {
  margin-top: 20px;
}

.container {
  padding: 30px;
  max-width: 500px;

  .anonymousText {
    margin-top: 10px;
    font-weight: 400;
    color: $karl-gray-8;
  }
}
