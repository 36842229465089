@import "~/styles/config.scss";

.settingsContainer {
  .headerCont {
    .headerText {
      margin-top: 0;
    }
    .subtitleText {
      margin-top: 7px;
      display: flex;
      align-items: center;
      color: $textGrey;
    }
  }
  .routesCont {
    padding-top: 20px;
  }
}
