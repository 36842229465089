.logisticCont {
  max-width: 400px;
  margin: 10px auto;
}

.topBar {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 4px;
}

.buttonRow {
  display: flex;
  justify-content: space-between;
  :last-child {
    margin-right: 0px;
  }
}

.buttonContainer {
  display: flex;
  margin-right: 10px;
  flex-grow: 1;
}

.icon {
  margin-bottom: 7px;
}

.buttonContents {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.logisticButton {
  border-radius: 8px !important;
  flex-grow: 1;
  padding: 10px !important;
}
