@import "~/styles/config.scss";

.editButtonContainer {
  position: fixed;
  z-index: 1000;
  top: 17px;
  right: 21px;
  .editButton {
    position: relative;
    z-index: 1000;
    width: 40px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #f4f5f7;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    color: #bbbbbb;
    transition: color 0.4s;
  }
  .editButton:hover {
    color: black;
    background: #ffffff;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .guideContainer {
    width: 100%;
    max-width: 550px;
    box-sizing: border-box;
    margin-top: 20px;
    margin-bottom: 20px;
    @include sm {
      border: 1px solid #e3e2e5;
      border-radius: 10px;
    }
    padding: 0px 25px;
    padding-bottom: 25px;
    .stepper {
      padding: 0;
    }
  }

  .madeWithLove {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
