@import "~/styles/config.scss";

.scheduleExpandedEventContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  border: 1px solid #f1f1f1;
  border-radius: 8px;
  padding: 20px;
  @include sm {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 115px 1fr;
    column-gap: 20px;
    align-items: flex-start;
    grid-template-areas: "profile text";
    grid-template-columns: 30% 1fr;
  }
  &:not(:first-child) {
    margin-top: 5px;
  }
  .text {
    grid-area: text;
    .title,
    .time {
      font-weight: normal;
      margin: 0;
      .icon {
        margin-left: 5px;
      }
    }
    .time {
      font-size: 13px;
      line-height: 15px;
    }
    .description {
      margin-top: 5px;
    }
  }
  .avatarCont {
    border-top: 1px #dbdbdb solid;
    margin-top: 20px;
    padding-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    @include sm {
      grid-area: profile;
      flex-direction: column;
      align-items: center;
      border-top: none;
      margin-top: 0;
      padding-top: 0;
    }
    .avatarText {
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include sm {
        text-align: center;
      }
    }
    .avatar {
      height: 70px;
      width: 70px;
      margin-right: 20px;
      @include sm {
        margin-right: 0;
        width: 100px;
        height: 100px;
      }
    }
    .nameCont {
      display: flex;
      align-items: center;
      @include sm {
        justify-content: center;
      }
      .header {
        margin-top: 6px;
        display: flex;
        align-items: center;
      }
      .iconButton {
        height: 42px;
        width: 42px;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          color: $linkedInBlue;
        }
        @include sm {
          display: none;
        }
      }
    }
    .iconButton {
      height: 42px;
      width: 42px;
      font-size: 20px;
      display: none;
      align-items: center;
      justify-content: center;
      &:hover {
        color: $linkedInBlue;
      }
      @include sm {
        display: flex;
      }
    }
  }
}
