@import "~/styles/config.scss";

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  border-radius: 5px;
  height: 220px;
  width: 48%;
  padding: 30px;
  margin-bottom: 6px;
  overflow: hidden;
  .cardTitle,
  .cardIcon {
    margin-top: 0;
    text-align: center;
    margin-bottom: 15px;
  }
  &.cardWithoutDescription {
    height: 140px;
    justify-content: center;
    .cardTitle {
      margin-bottom: 0;
    }
  }
  &:not(.cardWithoutIcon) {
    justify-content: flex-start;
    &.cardWithoutDescription {
      height: 190px;
    }
  }
  &:not(.cardWithoutDescription):after {
    content: "";
    position: absolute;
    height: 60px;
    width: 100%;
    bottom: 0;
    left: 0;
    border-radius: 5px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 50%,
      #ffffff 100%
    );
  }
  .cardDescription {
    max-height: 80%;
    overflow-wrap: anywhere;
  }
  &:hover {
    cursor: pointer;
  }
  @include sm {
    width: 32%;
  }
}

.cardDialogBackground {
  .cardDialog {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px;
    border-radius: 5px;
    .cardTitle,
    .cardIcon {
      margin-top: 0;
      text-align: center;
      margin-bottom: 15px;
    }
  }
}
