.adminSetupContainer {
  display: flex;

  .inputContainer {
    display: flex;
    flex-direction: column;
    flex: auto;
    padding: 10px;

    h3 {
      margin: 0;
    }

    .companySearch {
      width: 200px;
    }

    .urlInputContainer {
      width: 50%;
      margin-top: 50px;
      align-self: center;

      .urlInput {
        width: 100%;
        padding-left: 10px;
        border: 2px solid #eeeeee;
        border-radius: 5px;
        margin-bottom: 10px;
      }
    }
  }

  .testGuideContainer {
    border: 2px solid #eeeeee;
    width: 500px;
    height: 100vh;
  }
}
