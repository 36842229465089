@import "~/styles/config.scss";

.freeformContainer {
  margin-top: 20px;
}

.buttonContainer {
  text-align: right;
  margin-top: 10px;
}
