@import "~/styles/config.scss";

.globalEditContainer {
  height: 30px;
  margin-bottom: 20px;
  .text {
    display: flex;
    align-items: center;
    .globeIcon {
      font-size: 24px;
      margin-right: 7px;
    }
  }
}
