@import "~/styles/config.scss";

.container {
  width: 100%;
  text-align: center;
  padding: 5px 0;
}

.emoji {
  display: inline-block;
  font-size: 24px;
  cursor: pointer;
  width: 44px;
  height: 44px;
  line-height: 44px;
  vertical-align: middle;
  border-radius: 4px;
  &:hover {
    background-color: $karl-gray-3;
  }
}
