@import "~/styles/config.scss";

.dialogContainer {
  width: 100%;
  max-width: 550px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  .title {
    margin: 0;
    white-space: break-spaces;
    word-break: break-word;
  }
  .time {
    margin-top: 8px;
  }
  .description {
    margin-top: 16px;
    white-space: pre-line;
    a {
      text-decoration: underline;
      color: link;
    }
  }
  .interviewersContainer {
    display: flex;
    flex-direction: column;
  }
}

.expansionHeader {
  div {
    display: flex;
    align-items: center;
    .name {
      margin: 0;
      margin-left: 10px;
    }
  }
}

.detailsCont {
  display: flex;
  flex-direction: column;
  .title {
    white-space: break-spaces;
    word-break: break-word;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    h6 {
      margin: 0;
    }
    .icon {
      height: 50px;
      width: 50px;
      &:hover {
        color: $linkedInBlue;
      }
    }
  }
}
