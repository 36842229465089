@import "~/styles/config.scss";

.locationContainer {
  .headerCont {
    display: flex;
    .text {
      margin: 0;
      margin-right: 20px;
    }
  }
  .locationsFlexCont {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -20px;
    .locationCard {
      width: 200px;
      height: 200px;
      margin-bottom: 10px;
      margin-right: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      background-size: cover;
      .actionArea {
        width: 100%;
        height: 100%;
        background-size: cover;

        .text {
          margin: 0;
          font-weight: 800;
        }
        .address {
          overflow: hidden;
          max-height: 0px;
          opacity: 0;
        }
        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }
  }
}
