@import "~/styles/config.scss";

.map {
  width: 100%;
  margin: 0px 0px 20px 0px;
}

.quillEditorContainer {
  margin-bottom: 20px;
}

.titleBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  .title {
    margin-top: 0;
  }
}
